/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import Layout from "./layout"
import Title from "./title"
import Listing from "./listing"
import List from "./list"
import useMinimalBlogConfig from "../hooks/use-minimal-blog-config"
import replaceSlashes from "../utils/replaceSlashes"
// @ts-ignore
import Hero from "../texts/hero"
// @ts-ignore
import Bottom from "../texts/bottom"
import SEO from './seo'

type PostsProps = {
  posts: {
    slug: string
    title: string
    date: string
    excerpt: string
    description: string
    timeToRead?: number
    tags?: {
      name: string
      slug: string
    }[]
  }[]
}

const Homepage = ({ posts }: PostsProps) => {
  const { basePath, blogPath } = useMinimalBlogConfig()

  return (
    <Layout>
      <SEO title="Startseite des Blogs Regionalgeschichte des Oberen Mittelrheintals" description="Beiträge zur Vergangenheit und Gegenwart des Oberen Mittelrheintals" />
      <section sx={{ mb: [2, 3, 4], p: { fontSize: [2, 2, 3], mt: 2 } }}>
        <Hero />
      </section>
      <section sx={{ mb: [5, 6, 6], p: { fontSize: [2, 2, 3], mt: 2 } }}>
        <p>
        Einige haben meinen Blog immer wieder verfolgt, andere können vielleicht neugierig sein auf das, was auch noch alles drin war. Für alle gibt es jetzt den Hinweis,  dass <span sx={{fontWeight:600}}>aus den Blogs ein Buch hervorgegangen ist, das in diesen Tagen erscheint.</span>
        </p>
        <p><strong>Dieter Kramer</strong><br/>
        <span sx={{fontSize:3,color:"blue"}}> Gemeinsinn und Kreativität. Geschichten vom Mittelrhein und der Lahn</span><br/><span sx={{fontSize:2}}>Zeichnungen von Claus Gajus Gorsler/Frankfurt am Main</span><br/>
        <span sx={{fontSize:2}}>Lahnstein: Imprimatur Verlag 2022. 372 S. ca. 19,50 €, ISBN 978-3-947874-10-1</span></p>

        <a href="/inhaltsverzeichnis.pdf" rel="norefferer nofollow" target="_blank" >Inhaltsverzeichnis laden</a>
      </section>
      <Title text="Letzte Veröffentlichungen">
        <Link to={replaceSlashes(`/${basePath}/${blogPath}`)}><span sx={{fontSize:[1,2],pt:2,px:2,color:`red !important`}}> &rarr; Alle Artikel</span></Link>
      </Title>
      <Listing posts={posts} showTags={false} />
      <List>
        <Bottom />
      </List>
    </Layout>
  )
}

export default Homepage
