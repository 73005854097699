/** @jsx jsx */

import Bild from './dieter.jpg'
import {jsx,Styled} from 'theme-ui'
import {Link} from 'gatsby'


const Hero = () => (

<div sx={{ fontSize: [3,4], fontWeight: `300`, color: `text` }}>
  <div sx={{
    background: `url(${Bild})`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    backgroundPosition: `10% 45%`,
    height:[380,550]
    
  }} />

  <Styled.p>Prof. Dr. Dieter Kramer, <br/>
  Ao. Prof. Europäische Ethnologie, Universität Wien<br/>
  <Link sx={{fontSize:[1,2]}} to="/vita">Vita</Link>
  </Styled.p>
 

  
 
 </div>

)

export default Hero
